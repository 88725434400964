import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Grid, Box, Typography } from '@mui/material';


function Login({ onLoginSuccess }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const login = async () => {
        const response = await axios.post('https://api.aimigo.world/login', { username, password });
        localStorage.setItem('token', response.data.token);
        onLoginSuccess(response.data.token);
    };

    return (
        <Box p={3}>
            <Typography variant="h4" align="center" gutterBottom>Login</Typography>
            <Typography variant="body2" align="center" color="textSecondary" gutterBottom>
                Please fill in your credentials to login
            </Typography>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <TextField label="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </Grid>
                <Grid item>
                    <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={login}>Login</Button>
                </Grid>
                <Grid item>
                    <Button color="secondary" onClick={() => window.location.href = '/forgot-password'}>
                        Forgot Password
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default Login;