import React from 'react';
import { Button, Box, Typography } from '@mui/material';

function AuthButtons({ onSignupOpen, onLoginOpen }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                alignItems: 'center'
            }}
        >
            <Typography variant="h5">Welcome!</Typography>
            <Typography>Please log in or sign up to continue.</Typography>
            <Button
                variant="contained"
                color="primary"
                onClick={onSignupOpen}
                sx={{ mt: 3 }}
            >
                Sign Up
            </Button>
            <Button
                variant="outlined"
                color="primary"
                onClick={onLoginOpen}
            >
                Log In
            </Button>
        </Box>
    );
}

export default AuthButtons;
