import React from 'react';
import { Dialog, Box, Paper } from '@mui/material';
import SignUp from './Signup';
import LogIn from './Login';
import AuthButtons from './AuthButtons';


function Authenticator(props) {
    return (
        <Paper elevation={3} sx={{ padding: 2, marginBottom: 3, width: '100%' }}>
            <Box sx={{ my: props.spacing }}>
                <Dialog open={props.openSignup} onClose={() => props.setOpenSignup(false)}>
                    <SignUp onSignupSuccess={props.handleLoginSuccess} />
                </Dialog>
                <Dialog open={props.openLogin} onClose={() => props.setOpenLogin(false)}>
                    <LogIn onLoginSuccess={props.handleLoginSuccess} />
                </Dialog>
                <AuthButtons onSignupOpen={() => props.setOpenSignup(true)} onLoginOpen={() => props.setOpenLogin(true)} />
            </Box>
        </Paper>
    );
}

export default Authenticator;