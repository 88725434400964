import React from 'react';
import { Box, Tab, Tabs, AppBar, Select, MenuItem, Paper, Typography } from '@mui/material';
import PropertyForm from './PropertyForm';
import MemoryBank from './MemoryBank';
import AdminCodeGenerator from './AdminCodeGenerator';
import PropertyCreator from './PropertyCreator';
import PropertyGroupCreator from './PropertyGroupCreator';
import DeleteComponent from './DeleteComponent';

export default function PropertyDashboard(props) {
    return (<Paper elevation={3} sx={{
        padding: 2,
        marginBottom: 3,
        width: '100%'
    }}>
        <Box mb={4}>
            <Typography variant="h6" color="text.primary" sx={{ mb: 2 }}>
                Select a Property for Management
            </Typography>
            <Select
                sx={{ minWidth: 200, my: props.spacing }}
                value={props.activeProperty}
                onChange={e => props.setActiveProperty(e.target.value)}>
                {Array.isArray(props.properties) && props.properties.map(property =>
                    <MenuItem key={property.id} value={property}>
                        {property.name}
                    </MenuItem>
                )}
            </Select>
            <Typography variant="caption" color="text.secondary" sx={{ mt: 1 }}>
                Your selection will set the context for the management tools and forms below.
            </Typography>
            {props.activeProperty && <Paper elevation={3} sx={{
                padding: 2,
                marginBottom: 3,
                width: '100%'
            }}>

                <PropertyForm onGenerateLink={props.generateLink} propertyId={props.propertyId} startDate={props.startDate} endDate={props.endDate} handleChange={props.handleChange} generatedLink={props.generatedLink} />
            </Paper>}
            <Paper elevation={3} sx={{
                padding: 2,
                marginBottom: 3,
                width: '100%'
            }}>
                <PropertyGroupCreator onCreate={props.handleCreatePropertyGroup} />
            </Paper>
            <br />
            <Paper elevation={3} sx={{
                padding: 2,
                marginBottom: 3,
                width: '100%'
            }}>
                <PropertyCreator propertyGroups={props.propertyGroups} onCreate={props.handleCreateProperty} />
            </Paper>
            <br />
            <Paper elevation={3} sx={{
                padding: 2,
                marginBottom: 3,
                width: '100%'
            }}>
                <AppBar position="static" color="default">
                    <Tabs value={props.activeProperty ? props.activeTab : 0} onChange={props.handleTabChange} indicatorColor="primary" textColor="primary" variant="fullWidth">
                        <Tab label="Business" />
                        <Tab label="Property" disabled={!props.activeProperty} />
                        <Tab label="Group" disabled={!props.activeProperty?.groupId} />
                    </Tabs>
                </AppBar>
                {props.activeTab === 0 && <MemoryBank type="business" property={props.activeProperty} />}
                {props.activeProperty && props.activeTab === 1 && <MemoryBank type="property" property={props.activeProperty} />}
                {props.activeProperty && props.activeProperty?.groupId && props.activeTab === 2 && <MemoryBank type="group" property={props.activeProperty} />}
            </Paper>
            <Paper elevation={3} sx={{
                padding: 2,
                marginBottom: 3,
                width: '100%'
            }}>

                <DeleteComponent properties={props.properties} propertyGroups={props.propertyGroups} onDeleteProperty={props.handleDeleteProperty} onDeletePropertyGroup={props.handleDeletePropertyGroup} handleUpdatePropertyName={props.handleUpdatePropertyName} handleUpdatePropertyGroupName={props.handleUpdatePropertyGroupName} />

            </Paper>
            <Paper elevation={3} sx={{
                padding: 2,
                marginBottom: 3,
                width: '100%'
            }}>

                <AdminCodeGenerator />

            </Paper>


        </Box>
    </Paper>);
}
