import React, { useState, useEffect } from 'react';
import { Grid, Button, Box, Paper } from '@mui/material';
import MemoryCard from './MemoryCard';
import { v4 as uuidv4 } from 'uuid';
import { useMediaQuery, useTheme } from '@mui/material';

function MemoryBank({ type, property }) {
    const [memories, setMemories] = useState([]);


    const theme = useTheme();
    const isXSmall = useMediaQuery(theme.breakpoints.down('xs'));
    const isSmall = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const isMedium = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const isLarge = useMediaQuery(theme.breakpoints.up('lg'));

    let minWidth;

    if (isXSmall) {
        minWidth = '15em';  // Assuming 1 card per row
    } else if (isSmall) {
        minWidth = '30em';  // Assuming 2 cards per row
    } else if (isMedium) {
        minWidth = '45em';  // Assuming 3 cards per row
    } else if (isLarge) {
        minWidth = '55em';  // Assuming 4 cards per row
    }

    // Categories for general holiday home business information
    const businessCategories = ['Business Name', 'Business Address', 'Business Email', 'Business Phone Number', 'Customer Support Contact', 'Business Website', 'Business Hours', 'Registration/ License Number'];

    // Categories for a group of properties in a single location
    const groupCategories = ['Location Name', 'Total Number of Properties', 'Available Amenities', 'Public Transportation Info', 'Nearby Attractions', 'Local Cuisine and Restaurants', 'Emergency Services Info', 'Climate/ Weather Info', 'Local Events and Festivals'];

    // Categories for specific property information
    const propertyCategories = ['Property Name', 'Property Address', 'Property Type', 'Number of Rooms', 'Room Types', 'Amenities', 'Price Range', 'Check-in / Check-out Time', 'Pet Policy', 'Smoking Policy', 'Accessibility Features', 'Parking Information', 'Wi-Fi Availability', 'Cleaning Services'];


    const handleAddMemory = () => {
        const newMemory = { id: uuidv4(), data: '', info: '' };
        setMemories(prevMemories => [...prevMemories, newMemory]);
    };

    useEffect(() => {
        // fetch memories on component mount
        const token = localStorage.getItem('token');
        if (type !== 'business' && !property) return;

        fetch(`https://api.aimigo.world/memories/${type}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Property': JSON.stringify(property),
            },
        })
            .then(res => res.json())
            .then(data => {
                // Check if data is an array
                if (Array.isArray(data)) {
                    setMemories(data);
                } else {
                    // If not, set memories to an array with a single empty memory
                    setMemories([{ id: uuidv4(), data: '', info: '' }]);
                }
            });
    }, [property, type]);


    const handleSaveMemories = () => {
        // In your frontend, when making requests to your backend,
        // make sure to include the user's token in the request headers

        const token = localStorage.getItem('token');
        fetch(`https://api.aimigo.world/memories/${type}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'Property': JSON.stringify(property), // Include the property in the request headers
            },
            body: JSON.stringify(memories),
        });

    };

    const handleDeleteMemory = (id) => {
        setMemories(prevMemories => prevMemories.filter(memory => memory.id !== id));
    };

    const handleChange = (id, field, event) => {
        setMemories(prevMemories =>
            prevMemories.map(memory =>
                memory.id === id ? { ...memory, [field]: event.target.value } : memory
            )
        );
    };


    return (
        <Paper elevation={3} sx={{ padding: 3, marginTop: 3, marginBottom: 3 }}>
            <Box sx={{ marginBottom: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" color="primary" onClick={handleSaveMemories} sx={{ marginRight: 2 }}>
                    Save Memories
                </Button>
                <Button variant="contained" color="primary" onClick={handleAddMemory}>
                    Add Memory
                </Button>
            </Box>
            <Grid container spacing={2} sx={{ minWidth: minWidth }}> {/* Update this line */}
                {memories.map((memory) => (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={memory.id}>
                        <MemoryCard
                            memory={memory}
                            handleChange={(field, e) => handleChange(memory.id, field, e)}
                            handleDelete={() => handleDeleteMemory(memory.id)}
                            categories={type === 'business' ? businessCategories : type === 'group' ? groupCategories : propertyCategories}
                        />
                    </Grid>
                ))}
            </Grid>
        </Paper>
    );
}

export default MemoryBank;
