import React from 'react';
import { Card, CardContent, TextField, Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import Autocomplete from '@mui/material/Autocomplete';

function MemoryCard({ memory, handleChange, handleDelete, categories }) {

    return (
        <Card>
            <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Autocomplete
                        fullWidth
                        freeSolo
                        options={categories}
                        value={memory.data}
                        onChange={(event, newValue) => {
                            handleChange('data', { target: { value: newValue } });
                        }}
                        onInputChange={(event, newInputValue) => {
                            handleChange('data', { target: { value: newInputValue } });
                        }}
                        renderInput={(params) => (
                            <TextField {...params}
                                label="Category"
                                variant="outlined"
                                fullWidth
                            />
                        )}
                    />
                    <IconButton
                        aria-label="delete"
                        size="small"
                        onClick={handleDelete}
                    >
                        <DeleteIcon fontSize="inherit" />
                    </IconButton>
                </Box>
                <TextField
                    label="Info"
                    variant="outlined"
                    value={memory.info}
                    onChange={(e) => handleChange('info', e)}
                    fullWidth
                    multiline
                    minRows={3}
                    maxRows={3}
                    style={{ marginTop: '10px' }}
                />
            </CardContent>
        </Card>
    );
}

export default MemoryCard;
