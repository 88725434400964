import React, { useState, useEffect } from 'react';
import { Button, TextField, Select, MenuItem, InputLabel, FormControl, Grid, Paper } from '@mui/material';
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';


const defaultLocation = {
    lat: 40.712776,
    lng: -74.005974, // Coordinates for New York city as default
};

function PropertyCreator({ propertyGroups = [], onCreate }) {
    const [propertyData, setPropertyData] = useState({ name: '', groupId: '', location: defaultLocation });
    const [mapCenter, setMapCenter] = useState(defaultLocation);

    useEffect(() => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                const userLocation = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude,
                };
                setPropertyData(prev => ({ ...prev, location: userLocation }));
                setMapCenter(userLocation);
            },
            (error) => {
                console.log(error);
            },
            { enableHighAccuracy: true, timeout: 20000, maximumAge: 1000 },
        );
    }, []);

    const handleChange = (event) => {
        setPropertyData(prev => ({ ...prev, [event.target.name]: event.target.value }));
    };

    const handleMarkerDragEnd = (event) => {
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setPropertyData(prev => ({ ...prev, location: { lat, lng } }));
        setMapCenter({ lat, lng });
    };

    const handleCreate = () => {
        onCreate(propertyData);
    };

    return (
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2 }}>
                    <Grid container spacing={3} alignItems="flex-end">
                        <Grid item xs={12} sm={6}>
                            <TextField name="name" label="Property Name" value={propertyData.name} onChange={handleChange} required fullWidth />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl fullWidth>
                                <InputLabel id="group-select-label">Property Group</InputLabel>
                                <Select
                                    labelId="group-select-label"
                                    id="group-select"
                                    name="groupId"
                                    value={propertyData.groupId}
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">Select Group</MenuItem>
                                    {propertyGroups && Array.isArray(propertyGroups) && propertyGroups.map(group => (
                                        <MenuItem key={group.id} value={group.id}>{group.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Paper elevation={3} sx={{ padding: 2, height: '400px' }}>
                    <LoadScript googleMapsApiKey="AIzaSyDgZKTSLqW6xyFFXDWCFMrTGc2F2Kg7rD8" libraries={['places']}>
                        <GoogleMap
                            id="map"
                            mapContainerStyle={{ width: '100%', height: '100%' }}
                            center={mapCenter}
                            zoom={10}
                        >
                            <Marker
                                position={propertyData.location}
                                draggable={true}
                                onDragEnd={handleMarkerDragEnd}
                            />
                        </GoogleMap>
                    </LoadScript>
                </Paper>
            </Grid>
            <Grid item xs={12}>
                <Button variant="contained" color="primary" onClick={handleCreate} fullWidth>Create Property</Button>
            </Grid>
        </Grid>
    );

}

export default PropertyCreator;
