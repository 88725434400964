import React, { useState } from 'react';
import { Button, Grid, TextField, Paper, Typography, Select, MenuItem } from '@mui/material';

function DeleteComponent({
    properties,
    propertyGroups,
    onDeleteProperty,
    onDeletePropertyGroup,
    handleUpdatePropertyName,
    handleUpdatePropertyGroupName
}) {
    const [selectedProperty, setSelectedProperty] = useState(null);
    const [selectedGroup, setSelectedGroup] = useState(null);
    const [newPropertyName, setNewPropertyName] = useState('');
    const [newGroupName, setNewGroupName] = useState('');

    const handleDeleteProperty = () => {
        if (selectedProperty) {
            onDeleteProperty(selectedProperty);
            setSelectedProperty(null);
        }
    }

    const handleDeleteGroup = () => {
        if (selectedGroup) {
            onDeletePropertyGroup(selectedGroup);
            setSelectedGroup(null);
        }
    }

    const handleUpdateProperty = () => {
        if (selectedProperty && newPropertyName) {
            handleUpdatePropertyName(selectedProperty, newPropertyName);
            setNewPropertyName('');
        }
    }

    const handleUpdateGroup = () => {
        if (selectedGroup && newGroupName) {
            handleUpdatePropertyGroupName(selectedGroup, newGroupName);
            setNewGroupName('');
        }
    }

    return (
        <Paper elevation={3} sx={{ padding: 3, marginTop: 3, marginBottom: 3 }}>
            <Typography variant="h4" gutterBottom>Property Management</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Property Settings</Typography>
                    <Select
                        value={selectedProperty}
                        onChange={e => setSelectedProperty(e.target.value)}
                        fullWidth
                    >
                        {properties.map(property => (
                            <MenuItem value={property.id} key={property.id}>{property.name}</MenuItem>
                        ))}
                    </Select>
                    <TextField
                        value={newPropertyName}
                        onChange={e => setNewPropertyName(e.target.value)}
                        fullWidth
                        placeholder="New property name"
                        sx={{ marginBottom: 2 }}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" onClick={handleUpdateProperty} fullWidth>Update Property Name</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="secondary" onClick={handleDeleteProperty} fullWidth>Delete Property</Button>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <Typography variant="h6" gutterBottom>Property Group Settings</Typography>
                    <Select
                        value={selectedGroup}
                        onChange={e => setSelectedGroup(e.target.value)}
                        fullWidth
                    >
                        {propertyGroups.map(group => (
                            <MenuItem value={group.id} key={group.id}>{group.name}</MenuItem>
                        ))}
                    </Select>
                    <TextField
                        value={newGroupName}
                        onChange={e => setNewGroupName(e.target.value)}
                        fullWidth
                        placeholder="New group name"
                        sx={{ marginBottom: 2 }}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Button variant="contained" color="primary" onClick={handleUpdateGroup} fullWidth>Update Group Name</Button>
                        </Grid>
                        <Grid item xs={6}>
                            <Button variant="contained" color="secondary" onClick={handleDeleteGroup} fullWidth>Delete Group</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default DeleteComponent;
