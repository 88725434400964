import React, { useState, useEffect } from 'react';
import { Button, Grid, Paper, Typography, TextField, Box, Slider, Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import axios from 'axios';

function AdminCodeGenerator() {
    const [codes, setCodes] = useState([]);
    const [isAdmin, setIsAdmin] = useState(false);
    const [numCodes, setNumCodes] = useState(1);

    useEffect(() => {
        const checkAdminStatus = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                console.error("You must be logged in to check admin status.");
                return;
            }

            try {
                const response = await axios.get('https://api.aimigo.world/check-admin', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                setIsAdmin(response.data.isAdmin);
            } catch (error) {
                console.error("Error checking admin status: ", error);
            }
        };
        checkAdminStatus();
    }, []);

    const generateCodes = async () => {
        const token = localStorage.getItem('token');
        if (!token || !isAdmin) {
            console.error("You must be logged in as an admin to generate a code.");
            return;
        }

        let newCodes = [];
        for (let i = 0; i < numCodes; i++) {
            try {
                const response = await axios.post('https://api.aimigo.world/generate-code', {}, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                newCodes.push(response.data.code);
            } catch (error) {
                console.error("Error generating code: ", error);
            }
        }
        setCodes(newCodes);
    };

    const copyToClipboard = (code) => {
        navigator.clipboard.writeText(code);
    }

    const downloadCSV = () => {
        const csvContent = "data:text/csv;charset=utf-8," + codes.join('\n');
        const encodedUri = encodeURI(csvContent);
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "admin_codes.csv");
        document.body.appendChild(link); // Required for FF
        link.click();
    }

    return (
        <div>
            {isAdmin &&
                <Paper elevation={3} sx={{ padding: 3, marginTop: 3, marginBottom: 3 }}>
                    <Typography variant="h4" gutterBottom>Admin Code Generator</Typography>
                    <Typography variant="body1" gutterBottom>Generate admin codes to give to your customers at sign-up. You can generate between 1 to 10 codes at once.</Typography>
                    <Slider
                        defaultValue={1}
                        aria-label="Number of Codes"
                        valueLabelDisplay="auto"
                        step={1}
                        marks
                        min={1}
                        max={10}
                        onChange={(event, newValue) => {
                            setNumCodes(newValue);
                        }}
                    />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button variant="contained" color="primary" onClick={generateCodes} fullWidth>Generate Codes</Button>
                            {codes.length > 0 &&
                                codes.map((code, index) => (
                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
                                        <TextField
                                            value={code}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                            variant="outlined"
                                            fullWidth
                                        />
                                        <Tooltip title="Copy to Clipboard">
                                            <Button onClick={() => copyToClipboard(code)} sx={{ marginLeft: 2 }}><FileCopyIcon /></Button>
                                        </Tooltip>
                                    </Box>
                                ))
                            }
                            {codes.length > 0 &&
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 2 }}>
                                    <Button variant="contained" color="secondary" onClick={downloadCSV}>Download All Codes as CSV</Button>
                                </Box>
                            }
                        </Grid>
                    </Grid>
                </Paper>}
        </div>
    );
}

export default AdminCodeGenerator;
