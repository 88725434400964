import { Button, TextField } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';


function ForgotPassword() {
    const [email, setEmail] = useState('');

    const sendResetLink = async () => {
        try {
            await axios.post('https://api.aimigo.world/forgot-password', { email });
            alert('Password reset link has been sent to your email.');
        } catch (error) {
            console.error("Error sending reset link: ", error);
        }
    };

    return (
        <div>
            <h1>Forgot Password</h1>

            <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />

            <Button variant="contained" color="primary" onClick={sendResetLink}>
                Send Reset Link
            </Button>
        </div>
    );
}

export default ForgotPassword;
