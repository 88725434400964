//Whatsapp (maybe if it lets me)

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Box } from '@mui/material';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Authenticator from './Authenticator';
import PropertyDashboard from './PropertyDashboard';


function PropertyPage() {
    const [propertyId, setPropertyId] = useState('');
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());

    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [openSignup, setOpenSignup] = useState(!isAuthenticated);
    const [openLogin, setOpenLogin] = useState(false);
    const [generatedLink, setGeneratedLink] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [activeProperty, setActiveProperty] = useState(null);
    const [propertyGroups, setPropertyGroups] = useState([]);
    const [properties, setProperties] = useState([]);
    const [loadingProperties, setLoadingProperties] = useState(true);
    const [loadingPropertyGroups, setLoadingPropertyGroups] = useState(true);
    const spacing = 2;

    const [loadingAuth, setLoadingAuth] = useState(true);

    const [open, setOpen] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');


    useEffect(() => {
        const checkTokenValidityAndLoad = async () => {
            await checkTokenValidity();
            setLoadingAuth(false);
            fetchPropertyGroups(); // fetch property groups on component load
            fetchProperties(); // fetch properties on component load
        }
        checkTokenValidityAndLoad();
    }, []);




    useEffect(() => {
        if (!activeProperty && activeTab !== 0) {
            setActiveTab(0); // If no activeProperty is set and a tab other than 'Business' is currently active, switch to 'Business'
        } else if (activeProperty && !activeProperty.groupId && activeTab === 2) {
            setActiveTab(1); // If a property is set, but no group is set, and 'Group' tab is currently active, switch to 'Property'
        }
    }, [activeProperty, activeTab]);



    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    // fetch properties from server
    const fetchProperties = async () => {
        const token = localStorage.getItem('token');
        if (!token) return;
        try {
            const response = await axios.get('https://api.aimigo.world/properties', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.data && response.data.length > 0) {
                setProperties(response.data);
                setActiveProperty(response.data[0])
                setPropertyId(response.data[0].id)
            } else {
                setProperties([]);
                setActiveProperty(null);
                setPropertyId('');
            }
        } catch (error) {
            console.error('Error fetching properties: ', error);
        }
        finally {
            setLoadingProperties(false);
        }
    };


    // fetch property groups from server
    const fetchPropertyGroups = async () => {
        const token = localStorage.getItem('token');
        if (!token) return;
        try {
            const response = await axios.get('https://api.aimigo.world/property-groups', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setPropertyGroups(response.data);
        } catch (error) {
            console.error('Error fetching property groups: ', error);
        }
        finally {
            setLoadingPropertyGroups(false);
        }

    };


    const handleChange = (field) => (event) => {
        switch (field) {
            case 'startDate':
                setStartDate(event);
                break;
            case 'endDate':
                setEndDate(event);
                break;
            default:
                break;
        }
    };

    const handleLoginSuccess = (token) => {
        localStorage.setItem('token', token);
        setIsAuthenticated(true);
        setOpenLogin(false);
        setOpenSignup(false);
        window.location.reload();
    };


    const checkTokenValidity = async () => {
        const token = localStorage.getItem('token');

        if (!token) {
            setIsAuthenticated(false);
            return;
        }

        try {
            await axios.get('https://api.aimigo.world/auth/check-token', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setIsAuthenticated(true);
        } catch (error) {
            setIsAuthenticated(false);
        }
    };

    const generateLink = async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setOpenLogin(true);
            return;
        }
        try {
            const response = await axios.post('https://api.aimigo.world/generate-link', {
                propertyId,
                startDate,
                endDate,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            setGeneratedLink(`${window.location.origin}/setup/${response.data.uuid}`);

        } catch (error) {
            console.error("Error generating link: ", error);
            if (error.response && error.response.status === 403) {
                localStorage.removeItem('token');
                setIsAuthenticated(false);
                setOpenLogin(true);
            }
        }
    };


    const handleCreateProperty = async (propertyData) => {
        const token = localStorage.getItem('token');
        if (!token) return;
        try {
            const response = await axios.post('https://api.aimigo.world/properties', propertyData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // then add the property to state
            setProperties(prev => [...prev, response.data]);
            setActiveProperty(response.data); // set the newly created property as the active one
            setPropertyId(response.data.id); // also update the propertyId state
        } catch (error) {
            console.error('Error creating property: ', error);
            setErrorMessage('Error creating property: ' + error.message);
            setOpen(true);
        }
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    // handle property group creation
    const handleCreatePropertyGroup = async (groupName) => {
        const token = localStorage.getItem('token');
        if (!token) return;
        try {
            const response = await axios.post('https://api.aimigo.world/property-groups', { name: groupName }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // then add the group to state
            setPropertyGroups(prev => [...prev, response.data]);
        } catch (error) {
            console.error('Error creating property: ', error);
            setErrorMessage('Error creating property: ' + error.message);
            setOpen(true);
        }
    }
    // handle property deletion
    const handleDeleteProperty = async (propertyId) => {
        const token = localStorage.getItem('token');
        if (!token) return;
        try {
            await axios.post(`https://api.aimigo.world/delete-property/${propertyId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // remove the property from state
            setProperties(prev => prev.filter(property => property.id !== propertyId));
            // if the deleted property was the active one, unset it
            if (propertyId === activeProperty.id) {
                setActiveProperty(null);
                setPropertyId(null);
            }
        } catch (error) {
            console.error('Error deleting property: ', error);
        }
    }

    // handle property group deletion
    const handleDeletePropertyGroup = async (groupId) => {
        const token = localStorage.getItem('token');
        if (!token) return;
        try {
            await axios.post(`https://api.aimigo.world/delete-property-group/${groupId}`, {}, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // remove the group from state
            setPropertyGroups(prev => prev.filter(group => group.id !== groupId));
        } catch (error) {
            console.error('Error deleting property group: ', error);
        }
    }

    const handleUpdatePropertyName = async (propertyId, newName) => {
        const token = localStorage.getItem('token');
        if (!token) return;
        try {
            await axios.patch(`https://api.aimigo.world/properties/${propertyId}`, { name: newName }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // update the property name in state
            setProperties(prev => prev.map(property => property.id === propertyId ? { ...property, name: newName } : property));
        } catch (error) {
            console.error('Error updating property name: ', error);
        }
    }

    const handleUpdatePropertyGroupName = async (groupId, newName) => {
        const token = localStorage.getItem('token');
        if (!token) return;
        try {
            await axios.patch(`https://api.aimigo.world/property-groups/${groupId}`, { name: newName }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            // update the group name in state
            setPropertyGroups(prev => prev.map(group => group.id === groupId ? { ...group, name: newName } : group));
        } catch (error) {
            console.error('Error updating property group name: ', error);
        }
    }


    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: '#f5f5f5',
            }}
        >
            <Container
                maxWidth="md"
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '10px',
                    p: 4,
                }}
            >

                {loadingAuth &&
                    <p>Loading...</p>
                }
                {!loadingAuth && !isAuthenticated &&
                    <Authenticator openSignup={openSignup} setOpenSignup={setOpenSignup} openLogin={openLogin} setOpenLogin={setOpenLogin} spacing={spacing} handleLoginSuccess={handleLoginSuccess}></Authenticator>
                }

                {!loadingAuth && isAuthenticated && (loadingProperties || loadingPropertyGroups) &&
                    <p>Loading properties...</p>
                }
                {!loadingAuth && isAuthenticated && !loadingProperties && !loadingPropertyGroups &&
                    <PropertyDashboard propertyId={propertyId} startDate={startDate} endDate={endDate} generatedLink={generatedLink} activeTab={activeTab} activeProperty={activeProperty} setActiveProperty={setActiveProperty} propertyGroups={propertyGroups} properties={properties} spacing={spacing} handleTabChange={handleTabChange} handleChange={handleChange} generateLink={generateLink} handleCreateProperty={handleCreateProperty} handleCreatePropertyGroup={handleCreatePropertyGroup} handleDeleteProperty={handleDeleteProperty} handleDeletePropertyGroup={handleDeletePropertyGroup} handleUpdatePropertyName={handleUpdatePropertyName} handleUpdatePropertyGroupName={handleUpdatePropertyGroupName}></PropertyDashboard>
                }

            </Container>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    {errorMessage}
                </Alert>
            </Snackbar>

        </Box >
    );

}

export default PropertyPage; 