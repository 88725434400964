import React, { useState } from 'react';
import { Button, TextField, Paper, Grid } from '@mui/material';

function PropertyGroupCreator({ onCreate }) {
    const [groupName, setGroupName] = useState('');

    const handleCreate = () => {
        onCreate(groupName);
        setGroupName('');
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, marginTop: 3 }}>
            <Grid container spacing={2} alignItems="flex-end">
                <Grid item xs={12} sm={9}>
                    <TextField label="Group Name" value={groupName} onChange={e => setGroupName(e.target.value)} required fullWidth />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Button variant="contained" color="primary" onClick={handleCreate} fullWidth>Create Property Group</Button>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default PropertyGroupCreator;
