import React from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { Button, Grid, TextField, Paper, Typography, InputAdornment, IconButton } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';

function PropertyForm({ onGenerateLink, startDate, endDate, handleChange, generatedLink }) {
    const handleCopy = () => {
        navigator.clipboard.writeText(generatedLink);
    };

    return (
        <Paper elevation={3} sx={{ padding: 3, marginTop: 3, marginBottom: 3 }}>
            <Typography variant="h4" gutterBottom sx={{ marginBottom: 2 }}>Generate Customer Access Link</Typography>
            <Typography variant="body1" sx={{ marginBottom: 2 }}>To generate an access link for your customers, select the start and end date of their stay and click 'Generate Link'. Once the link is generated, provide it to your customers before their holiday so they can sign up for access to their AI chatbot.</Typography>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="Start Date"
                            value={startDate}
                            onChange={handleChange('startDate')}
                            renderInput={(props) => <TextField {...props} fullWidth />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            label="End Date"
                            value={endDate}
                            minDate={startDate}
                            onChange={handleChange('endDate')}
                            renderInput={(props) => <TextField {...props} fullWidth />}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={onGenerateLink} fullWidth>
                        Generate Link
                    </Button>
                </Grid>
            </Grid>
            {generatedLink &&
                <TextField
                    label="Generated Link"
                    value={generatedLink}
                    fullWidth
                    sx={{ marginTop: 2 }}
                    InputProps={{
                        readOnly: true,
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleCopy}>
                                    <FileCopyIcon />
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />}
        </Paper>
    );
}

export default PropertyForm;
