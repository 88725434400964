import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Container, Box, Typography, CircularProgress } from '@mui/material';

function UserConfirm() {
    const [isValid, setIsValid] = useState(null);

    // Get UUID from the URL
    const { uuid } = useParams();

    // Function to validate the uuid
    const validateUUID = async () => {
        try {
            const response = await axios.get(`https://api.aimigo.world/confirm/${uuid}`);
            if (response.status === 200) {
                setIsValid(true);
            }
        } catch (error) {
            setIsValid(false);
        }
    };

    // Validate the uuid when the component mounts
    useEffect(() => {
        validateUUID();
    });

    // Display different content based on the validity of the uuid
    let content;
    if (isValid === null) {
        content = (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Box>
        );
    } else if (isValid) {
        content = (
            <Typography variant="h4" align="center" gutterBottom>
                Congratulations! Your phone number has been successfully verified.
            </Typography>
        );
    } else {
        content = (
            <Typography variant="h4" align="center" gutterBottom>
                Oops! Something went wrong. We couldn't verify your phone number.
            </Typography>
        );
    }

    return (
        <Container>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '100vh' }}>
                {content}
            </Box>
        </Container>
    );
}

export default UserConfirm;
