import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField, Button, Paper, Box, Typography, CircularProgress } from '@mui/material';
import { useParams } from 'react-router-dom';
import { isValidPhoneNumber } from 'react-phone-number-input';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function UserForm() {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [isValid, setIsValid] = useState(null);
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [isVerified, setIsVerified] = useState(null);


    // Get UUID from the URL
    const { uuid } = useParams();

    // Function to validate the uuid
    const validateUUID = async () => {
        try {
            const response = await axios.get(`https://api.aimigo.world/check-valid-number/${uuid}`);
            if (response.status === 200) {

                setIsValid(true);
                setIsVerified(response.data.isVerified);
            }
        } catch (error) {
            setIsValid(false);
        }
    };


    // Validate the uuid when the component mounts
    useEffect(() => {
        validateUUID();
    }, []);

    // Function to handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        // Field validations
        if (!isValidPhoneNumber(phoneNumber)) {
            toast.error('Please enter a valid phone number');
            setLoading(false);
            return;
        }
        if (!email.includes('@')) {  // Simple email validation, consider using a library for production use
            toast.error('Please enter a valid email');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(`https://api.aimigo.world/setup/${uuid}`, { phoneNumber, email });

            if (response.status === 200) {
                setSubmitted(true);
                toast.success('Confirmation link sent to email and SMS');
            }
        } catch (error) {
            toast.error('Something went wrong, please try again');
        } finally {
            setLoading(false);
        }
    };

    if (isVerified) {
        return <div>You have already validated your phone number.</div>;
    }


    if (isValid === null) {
        return <div>Validating...</div>;
    }

    if (!isValid) {
        return <div>Link is not valid</div>;
    }

    if (submitted) {
        return <div>Thank you! A message has been sent to your phone.</div>;
    }

    return (

        <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="100vh"
            bgcolor="#f5f5f5"
        >
            <Paper
                component="form"
                onSubmit={handleSubmit}
                elevation={6}
                style={{
                    padding: "30px",
                    maxWidth: "400px",
                    background: 'white url("holiday-theme-background-url") no-repeat center/cover'
                }}
            >
                <Typography variant="h4" gutterBottom>User Registration</Typography>
                <Typography variant="subtitle1" gutterBottom>
                    <span>
                        Register here to get an AI assistant for your holiday. This AI assistant will provide...
                    </span>
                </Typography>
                <PhoneInput
                    international
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    style={{ margin: "10px 0" }}
                />
                <TextField
                    label="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={{ margin: "10px 0" }}
                    fullWidth
                />
                <Button variant="contained" color="primary" type="submit" style={{ margin: "10px 0" }} fullWidth>
                    {loading ? <CircularProgress size={24} /> : 'Submit'}
                </Button>
            </Paper>
        </Box>
    );
}

export default UserForm;
