import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button } from '@mui/material';
import { useParams } from 'react-router-dom';

function ResetPassword() {
    const [password, setPassword] = useState('');
    const { token } = useParams();

    const resetPassword = async () => {
        try {
            await axios.post('https://api.aimigo.world/reset-password', { token, password });
            alert('Password reset successful. You can now login with your new password.');
        } catch (error) {
            console.error("Error resetting password: ", error);
        }
    };

    return (
        <div>
            <h1>Reset Password</h1>

            <TextField
                label="New Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
            />

            <Button variant="contained" color="primary" onClick={resetPassword}>
                Reset Password
            </Button>
        </div>
    );
}

export default ResetPassword;
