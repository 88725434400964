import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, Grid } from '@mui/material';

function Signup({ onSignupSuccess }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');

    const signup = async () => {
        try {
            const response = await axios.post('https://api.aimigo.world/signup', { username, password, email, code });
            localStorage.setItem('token', response.data.token);
            onSignupSuccess(response.data.token);
        } catch (error) {
            console.error("Error signing up: ", error);
        }
    };

    return (
        <Box p={3}>
            <Typography variant="h4" align="center" gutterBottom>Signup</Typography>
            <Typography variant="body2" align="center" color="textSecondary" gutterBottom>
                Please fill the form below to create an account
            </Typography>
            <Grid container direction="column" spacing={2}>
                <Grid item>
                    <TextField label="Username" value={username} onChange={(e) => setUsername(e.target.value)} />
                </Grid>
                <Grid item>
                    <TextField label="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </Grid>
                <Grid item>
                    <TextField label="Password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                </Grid>
                <Grid item>
                    <TextField label="Signup Code" value={code} onChange={(e) => setCode(e.target.value)} />
                </Grid>
                <Grid item>
                    <Button variant="contained" color="primary" onClick={signup}>Signup</Button>
                </Grid>
            </Grid>
        </Box>
    );
}
export default Signup;