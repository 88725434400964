import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserForm from './UserForm';
import UserConfirm from './UserConfirm';
import PropertyPage from './PropertyPage';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/setup/:uuid" element={<UserForm />} />
        <Route path="/confirm/:uuid" element={<UserConfirm />} />
        <Route path="/" element={<PropertyPage isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/property" element={<PropertyPage isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />


      </Routes>
    </Router>
  );
}

export default App;
